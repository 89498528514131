<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        :to="{ name:'landing' }"
        class="brand-logo"
      >
        <vuexy-logo />
        <h2 class="brand-text">
          BEESUMMER<h2 />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            src="@/assets/images/pages/login-dark.svg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('welcome to') }} BEESUMMER 夏蜂👋🏻
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Please sign-in to your account and start the adventure') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('password') }}</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>{{ $t('Forgot Password?') }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t('Remember me') }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="submitted"
                @click="validationForm"
              >
                <div v-if="!submitted">
                  {{ $t('login') }}
                </div>

                <b-spinner
                  v-else
                  small
                />

              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('New on our platform?') }} </span>
            <b-link :to="{name:'signup'}">
              <span>&nbsp;{{ $t('Create account') }}</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <!-- <b-button
              variant="facebook"
              :disabled="true"
              @click="faceBookSignIn"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button> -->
            <b-button
              variant="google"
              @click="googleSignIn"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.60116 4.08692H4.97505V6.00414H7.63727C7.3895 7.22248 6.35227 7.92248 4.97505 7.92248C3.35116 7.92248 2.04227 6.61359 2.04227 4.98914C2.04227 3.36526 3.35116 2.05637 4.97505 2.05637C5.6745 2.05637 6.30672 2.3047 6.80283 2.71081L8.24727 1.26692C7.36727 0.4997 6.23894 0.0263672 4.97505 0.0263672C2.22283 0.0263672 0.0117188 2.23692 0.0117188 4.9897C0.0117188 7.74248 2.22227 9.95303 4.97505 9.95303C7.45672 9.95303 9.71339 8.14803 9.71339 4.9897C9.71339 4.69637 9.66839 4.38026 9.60116 4.08692Z"
                  fill="white"
                />
              </svg>
            </b-button>
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
  auth, usersCollection, firebase,
} from '../../firebase'

export default {
  components: {
    VuexyLogo,
    BSpinner,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      required,
      email,
      submitted: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.submitted = true
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          auth
            .doSignInWithEmailAndPassword(this.userEmail, this.password)
            .then(async res => {
              const log = {
                type: 'access',
                action: 'login',
                actionId: res.user?.uid,
              }
              await store.dispatch('logUser', log)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Success Logged in'),
                  icon: 'StarIcon',
                  variant: 'success',
                },
              })

              this.$router.go({ path: '/' })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message || 'Failed Sign in',
                  icon: 'AlertTriangleIcon',
                  variant: 'error',
                },
              })
              this.submitted = false
            })
        } else {
          this.submitted = false
        }
      }).catch(() => {
        this.submitted = false
      })
    },
    googleSignIn() {
      auth.doGoogleSignIn()
        .then(async res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Success Logged in'),
              icon: 'StarIcon',
              variant: 'success',
            },
          })
          if (res.additionalUserInfo.isNewUser) {
            usersCollection.doc(res.user.uid).set({
              uid: res.user?.uid,
              mediaInfo: res.additionalUserInfo.profile,
              roles: {},
              email: res.additionalUserInfo.profile.email.toLowerCase(),
              displayName: res.additionalUserInfo.profile.name,
              status: '',
              photoURL: res.additionalUserInfo.profile.picture,
              stores: [],
              settings: {},
              isDeleted: '',
              createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Success Logged in'),
                    icon: 'StarIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({ name: 'store-connect' })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message || 'Failed Sign in',
                    icon: 'AlertTriangleIcon',
                    variant: 'error',
                  },
                })
              })
          } else {
            const log = {
              type: 'access',
              action: 'login_google',
              actionId: res.user?.uid,
            }
            await store.dispatch('logUser', log)
            this.$router.go({ path: '/' })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message || 'Failed Sign in',
              icon: 'AlertTriangleIcon',
              variant: 'error',
            },
          })
        })
    },
    faceBookSignIn() {
      auth.doFacebookSignIn()
        .then(async res => {
          const log = {
            type: 'access',
            action: 'login_facebook',
            actionId: res.user?.uid,
          }
          await store.dispatch('logUser', log)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Success Logged in'),
              icon: 'StarIcon',
              variant: 'success',
            },
          })
          if (res.additionalUserInfo.isNewUser) {
            usersCollection.doc(res.user.uid).set({
              uid: res.user?.uid,
              mediaInfo: res.additionalUserInfo.profile,
              roles: [],
              email: res.additionalUserInfo.profile.email.toLowerCase(),
              displayName: res.additionalUserInfo.profile.name,
              status: '',
              photoURL: res.additionalUserInfo.profile.picture,
              stores: [],
              settings: {},
              isDeleted: '',
              createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success Logged in',
                    icon: 'StarIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({ name: 'store-connect' })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message || 'Failed Sign in',
                    icon: 'AlertTriangleIcon',
                    variant: 'error',
                  },
                })
              })
          } else {
            this.$router.go({ path: '/' })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message || 'Failed Sign in',
              icon: 'AlertTriangleIcon',
              variant: 'error',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
